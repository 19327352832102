import React, { useState, useEffect } from 'react';
import axios from "axios";
import ENDPOINT from "../../constants/api-endpoints";
import { useNavigate } from 'react-router-dom';


// Form component
const Logout = () => {
  // console.log(getToken());
  
const [logoutpage, setLogout] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    axios.post(ENDPOINT.LOGOUT, {})
    .then((response) => {
      // Handle data
      setLogout(response.data);
      localStorage.removeItem("BearerToken");
      localStorage.removeItem("loginUserId");
      navigate('/login');
      navigate(0);
    })
    .catch((error) => {
      console.log(error);
    })
  }, []);

  let message = '';
  return (
    <>
      <section className="full_width header_margin common_heading">
        <div className="container-fluid">
         <p>{ message }</p>
        </div>
      </section>
    </>
  );
};

export default Logout;
