import React from "react";
import { Link } from "react-router-dom";

import profile_logo from "../../css/images/profile_logo.png";

const LoginHeaderMenu = () => {
  return (
    <>
      <div className="details_page_heading">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3">
              <div className="inr_lgo">
                <a href="#">
                  <img src={profile_logo} alt="" />
                </a>
              </div>
            </div>
            <div className="col-lg-9 text-end m-top-30">
              {/* <a className="hamburger">
                <span className="h-top" />
                <span className="h-middle" />
                <span className="h-bottom" />
              </a> */}
              <nav className="sub-nav text-end">
                <ul>
                  <li>
                    <Link to="/my-favorite">My Favourite</Link>
                  </li>
                  <li>
                    <Link to="/my-account">Account</Link>
                  </li>
                  <li>
                    <Link to="/my-order">My Order</Link>
                  </li>
                  <li>
                    <Link to="/message">Messages</Link>
                  </li>
                  <li>
                    <Link to="/logout">Logout</Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginHeaderMenu;
