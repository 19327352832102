import Settings from '../constants/settings';
const apiEndPoint = Settings.API_END_POINT_URL || {};
const appUrl = Settings.APP_URL || {};
const apiUrls = {
    BASE_PATH: `${apiEndPoint}`,
    APP_BASE_PATH: `${appUrl}`,
    HOMEPAGE_DATA: `${apiEndPoint}/api/v1/homepageData`,
    BANNER_LIST: `${apiEndPoint}/api/v1/bannerList`,
    NAV_MENU: `${apiEndPoint}/api/v1/navigationMenu`,
    PAGE_DATA: `${apiEndPoint}/api/v1/pageData`,
    CONTACT_SUBMIT: `${apiEndPoint}/api/v1/contactSubmit`,
    ADDRESS_DATA: `${apiEndPoint}/api/v1/addressData`,
    BLOG_DATA: `${apiEndPoint}/api/v1/blogData`,
    TESTIMONIAL_DATA: `${apiEndPoint}/api/v1/testimonialData`,
    SETTINGS_DATA: `${apiEndPoint}/api/v1/settingsData`,
    NEWSLETTER_SUBMIT: `${apiEndPoint}/api/v1/newsletterSubmit`,
    META_DATA: `${apiEndPoint}/api/v1/metaData`,
    IMAGES_DATA: `${apiEndPoint}/api/v1/imageData`,


    PAGEDATA: `${apiEndPoint}/api/v1/get-page-data`,
    PUPPETGRAM_CAST: `${apiEndPoint}/api/v1/get-puppetgram-cast`,
    GET_PUPPET_DETAIL: `${apiEndPoint}/api/v1/get-puppet-detail`,
    ADD_PUPPET_FAVORITE: `${apiEndPoint}/api/v1/add-favorite`,
    MY_FAVORITE_PUPPET: `${apiEndPoint}/api/v1/my-favorite-puppet`,
    MY_FAVORITE_BACKGROUND: `${apiEndPoint}/api/v1/my-favorite-background`,

    USER_DETAIL: `${apiEndPoint}/api/v1/get-user-detail`,
    UPDATE_USER_DETAIL: `${apiEndPoint}/api/v1/update-user-detail`, 

    
    CATEGORY: `${apiEndPoint}/api/v1/get-categories-background`,
    CATEGORY_IMAGES_VIDEO: `${apiEndPoint}/api/v1/get-category-img-vid`,
    CATEGORY_IMAGES_VIDEO_DETAIL: `${apiEndPoint}/api/v1/get-category-img-vid-detail`,
    CATEGORY_VIDEOS: `${apiEndPoint}/api/v1/get-video-category`,
    // VIDEO_LENGTH_PRICE: `${apiEndPoint}/api/v1/get-video-length-price`,
    PUPPET_LENGTH_PRICE: `${apiEndPoint}/api/v1/get-puppet-length-price`,
    LENGTH_PRICE: `${apiEndPoint}/api/v1/get-length-price`,
    DELIVERY_FORMAT_PRICE: `${apiEndPoint}/api/v1/get-deliver-format-price`, 
    
    
    ADDITIONAL_PUPPET_PRICE: `${apiEndPoint}/api/v1/get-additional-puppet-price`,
    VIDEO_BACKGROUND_SECTION_PRICE: `${apiEndPoint}/api/v1/get-video-background-selection-price`,
    ORDER_CONFIRM: `${apiEndPoint}/api/v1/order-confirm`,
    PAYMENT_CONFIRM: `${apiEndPoint}/api/v1/payment-confirm`,
    MY_ORDER_LIST: `${apiEndPoint}/api/v1/my-order-list`,
    ORDER_DETAIL: `${apiEndPoint}/api/v1/order-detail`,

    MESSAGE_SEND: `${apiEndPoint}/api/v1/message-send`,
    MY_MESSAGE_LIST: `${apiEndPoint}/api/v1/my-message-list`,

    SOCIAL_MEDIA_LINK: `${apiEndPoint}/api/v1/get-social-media-link`,
    
    STATIC_PAGE_DATA: `${apiEndPoint}/api/v1/static-page-data`,
    CONTACT_US: `${apiEndPoint}/api/v1/contact-us`,
    REGISTER: `${apiEndPoint}/api/v1/register`,
    LOGIN: `${apiEndPoint}/api/v1/login`,
    LOGOUT: `${apiEndPoint}/api/v1/logout`,
    DOLLER_SIGN: `$`,

    STRIPE_KEY: `${apiEndPoint}/api/v1/get-stripe-secret-key`,
};
export default apiUrls;
